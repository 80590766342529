@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;1,300&family=Open+Sans:wght@300&family=Poppins:wght@500&family=Roboto:wght@300&display=swap');


*{
  margin:0;
  padding:0;
  /* font-family: 'Poppins', sans-serif; */
  /* font-family: 'Lato', sans-serif; */
  /* font-family: 'Roboto', sans-serif; */
  /* font-family: 'Open Sans', sans-serif; */
}

main{
  min-height:100vh;
}

.sign{
  width:250px;
}

.inputArea{
  width:500px;
  background:rgba(66, 66, 66, 0.356);
}

.search input{
  width:90%;
  border-top-left-radius:50px;
  border-bottom-left-radius:50px;
  outline:none;
  background:transparent;
  color:#fff;
  font-size:1rem;
}

::placeholder{
  color:#fff;
}

.container, .card{
  min-height:100vh;
}

.card-img-overlay{
  background:rgba(1, 2, 70, 0.507);
}

video{
  min-height:100vh;
  object-fit: cover;
  background-size:cover;
}

.date-time{
  font-family: 'Lato', sans-serif;
  font-size:2rem;
  letter-spacing:2px;
}


.icon{
  font-size:5rem;
}

.description{
  font-size:2rem;
}

.overlay-bottom{
  width:500px;
  background:rgba(95, 95, 95, 0.507);
  margin:0 auto;
  align-items:center;
  border-radius:20px;
}

@media only screen and (max-width: 590px){
  .overlay-bottom{
    width:300px;
  }
  video{
     min-height:150vh;
  }

}

@media only screen and (max-width: 280px){
  .overlay-bottom{
    width:250px;
  }
}

.bottom{
  font-size:1.7rem;
}

.bottom-txt{
  font-size:0.9rem;
}